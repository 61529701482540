import React from "react";
import gql from "graphql-tag";
import {datoClient} from "../apollo/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Query} from "react-apollo";
import {Link} from "gatsby";
import {categories, initials} from "../templates/stylist";

const STYLIST = gql`query Stylist($id: ItemId!) {
    stylist(filter: {id: {eq: $id}}) {
        name
        category
        city{
            name
        }
        avatar{
            url
        }
    }
}
`

const StylistDetail = ({stylistId}) => <Query
  query={STYLIST}
  variables={{id: stylistId}}
  client={datoClient}
>{({loading, error, data, ...v}) => {
  if (loading) {
    return <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/>
  }
  if (error) {
    return <div className="font-sans text-red-700">Something went wrong, please try again later</div>
  }
  const s = data.stylist
  return <div className='w-full border mb-8 flex flex-wrap justify-start items-center'>
    <img src={s.avatar.url + '?auto=format&dpr=1&fit=crop&h=200&w=200'} alt=""/>
    <div className="mx-4 flex flex-col justify-start">
      <h1
        className='font-sans font-light text-3xl tracking-wide text-black text-start my-4'>{initials(s.name)}</h1>
      {s.category && s.category.map(c => <div key={c}
                                              className='font-sans font-light text-base my-1'>{categories[c]}</div>)}
      {s.city && <div className='mb-2'>
        <FontAwesomeIcon className='text-green-700' icon={faMapMarkerAlt} size="1x"/>
        <span className='font-sans font-light mx-2'>{s.city.name}</span>
      </div>
      }
      <Link to={'/stylists/' + stylistId + '/'}
            className='text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'>View
        details</Link>

    </div>
  </div>
}}
</Query>

export default StylistDetail

