import React, {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart as farHeart} from '@fortawesome/free-regular-svg-icons'
import {faHeart as fasHeart} from '@fortawesome/free-solid-svg-icons'
import {Mutation} from "react-apollo";
import gql from "graphql-tag";
import {client} from '../apollo/client';
import {LikesContext} from "../context/LikesContext";
import {UserContext} from "../context/UserContext";

const LIKE = gql`
    mutation Like($input: LikeInput!) {
        like(input: $input)
    }
`

const Like = ({model, id}) => {
  const {user} = useContext(UserContext);
  const {likes, setLikes} = useContext(LikesContext);
  return <Mutation
    client={client}
    mutation={LIKE}>{(likeMutation) => {
    return user ?
      <FontAwesomeIcon
        className='text-red-700'
        onClick={async () => {
          likeMutation({
            variables: {
              input: {
                model,
                itemID: id,
                value: !likes[model].includes(id)
              }
            }
          })
          setLikes({
              ...likes,
              [model]:
                likes[model].includes(id) ?
                  likes[model].filter(v => v !== id) :
                  [...likes[model], id]
            }
          )
        }}
        icon={likes[model].includes(id) ? fasHeart : farHeart}
        size='1x'
      /> : <div></div>

  }}</Mutation>;
}

export default Like