import {CardElement, injectStripe} from "react-stripe-elements";
import React, {useState} from "react";
import {navigate} from "gatsby"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Mutation} from "react-apollo";
import gql from "graphql-tag";
import {client} from '../apollo/client';

const PAY_BOOKING_REQUEST_MUTATION = gql`
    mutation Pay($input: PayBookingRequestInput!) {
        payBookingRequest(input: $input)
    }
`

const PaymentForm = ({stripe, bookingRequestID}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  return <Mutation
    client={client}
    mutation={PAY_BOOKING_REQUEST_MUTATION}>{(payBookingRequest) => <form
    className='flex flex-col'
    onSubmit={async (ev) => {
      ev.preventDefault()
      setError(null)
      setIsSubmitting(true)
      const v = await stripe.createToken({})
      if (v.error) {
        setError(v.error.message)
      } else {
        const token = v.token.id
        const input = {bookingRequestID, token}
        const exp = await payBookingRequest({variables: {input}})
          .catch((e) => {
            console.log('error', e)
            setError('Something went wrong, please try again later.')
          })
        if (exp.data.payBookingRequest) {
          navigate('/bookingConfirmation')
        } else {
          setError('Something went wrong, please try again later.')
        }
      }
      setIsSubmitting(false)
    }}>
    <CardElement className='border p-2 ' style={{
      base: {
        fontSize: "20px"
      },
    }} hidePostalCode={true}/>
    {error && <div className='font-sans font-light text-red-700'>{error}</div>}
    <button
      className='my-4 text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'
      disabled={isSubmitting}
    >Confirm
      order
    </button>
    {isSubmitting ?
      <FontAwesomeIcon className='text-gray-800 mx-4 ' icon={faSpinner} spin={true} size="2x"/> : null}

  </form>}</Mutation>;
}

export default injectStripe(PaymentForm)
