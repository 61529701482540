import React, {useState} from "react";
import Autosuggest from 'react-autosuggest';
import './auto-suggest-enhanced.css'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'


const AutoSuggestEnhanced = ({
                               id,
                               initialValue = '',
                               placeholder = 'Choose a city...',
                               items,
                               onSelected,
                               onBlur = () => ({}),
                               getSuggestionValue = suggestion => suggestion.name
                             }) => {
  const [suggestions, setSuggestions] = useState([])
  const [value, setValue] = useState(initialValue)

  const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const getSuggestions = value => {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return items;
    }

    const regex = new RegExp('' + escapedValue, 'i');

    return items.filter(city => regex.test(getSuggestionValue(city)));
  };


  const getIndicesOf = (searchStr, str, caseSensitive) => {
    const searchStrLen = searchStr.length;
    if (searchStrLen === 0) {
      return [];
    }
    let startIndex = 0, index, indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  };

  const renderSuggestion = (suggestion, {query}) => {
    const suggestionText = getSuggestionValue(suggestion);
    const matches = getIndicesOf(query, suggestionText, false).map(v => [v, v + query.length]);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <span className="name">
        {
          parts.map((part, index) =>
            <span className={part.highlight ? 'font-bold' : null} key={index}>{part.text}</span>)
        }
      </span>
    );
  };

  const onSuggestionsFetchRequested = v => setSuggestions(getSuggestions(v.value))
  const onSuggestionsClearRequested = v => setSuggestions([])

  return <Autosuggest
    suggestions={suggestions}
    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    onSuggestionsClearRequested={onSuggestionsClearRequested}
    getSuggestionValue={getSuggestionValue}
    renderSuggestion={renderSuggestion}
    alwaysRenderSuggestions={false}
    highlightFirstSuggestion={true}
    shouldRenderSuggestions={() => true}
    onSuggestionSelected={(_, {suggestion}) => onSelected(suggestion)}
    inputProps={{
      id,
      placeholder,
      value: value,
      onBlur: onBlur,
      onChange: (_, {newValue}) => {
        onSelected(null)
        return setValue(newValue);
      }
    }}
  />
}

export default AutoSuggestEnhanced