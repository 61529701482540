import React, {useContext, useState} from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import {Link} from "gatsby";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMapMarkerAlt, faSpinner} from '@fortawesome/free-solid-svg-icons'
import Like from "../components/Like";
import SEO from "../components/seo";
import {ChatContext} from "../context/ChatContext";
import {UserContext} from "../context/UserContext";
import {graphql} from 'gatsby'

export const categories = {
  fashion: 'Fashion',
  hair: 'Hair',
  makeup: 'Make-up',
  photography: 'Photography',
}

export const initials = name => {
  const parts = name.split(' ')
  return parts[0] + ' ' + (parts.length > 1 ? parts[1].slice(0, 1) : '');
}

export default ({data: {datoCmsStylist: s}}) => {
  const {startTextChat, startVideoChat, ready} = useContext(ChatContext)
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)

  return (
    <Layout>
      <SEO title={'Stylist ' + initials(s.name)} seo={s.seoMetaTags}/>
      <div className="flex flex-col justify-start items-start md:mx-32 mx-2 my-16">
        <div className="flex justify-start w-full">
          {s.avatar && <Img style={{minWidth: 200}} fluid={s.avatar.fluid}/>}
          <div className="mx-4 flex flex-col justify-between items-start">
            <div className="flex">
              <h1
                className='font-sans font-light text-3xl tracking-wide text-black text-start'>{initials(s.name)}</h1>
              {ready && <>
                <button
                  className={'ml-4 text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-1 px-6 focus:outline-none focus:shadow-outline uppercase ' + (userContext.user ? '' : 'hidden')}
                  onClick={async () => {
                    if (!loading) {
                      setLoading(true)
                      setMessage(null)
                      const r = await startTextChat(s.originalId);
                      if (!r) {
                        setMessage('This stylist is not available for chat')
                      }
                      setLoading(false)
                    }
                  }}>Chat
                </button>
                <button
                  className={'ml-4 text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-1 px-6 focus:outline-none focus:shadow-outline uppercase ' + (userContext.user ? '' : 'hidden')}
                  onClick={async () => {
                    if (!loading) {
                      setLoading(true)
                      setMessage(null)
                      const r = await startVideoChat(s.originalId);
                      if (!r) {
                        setMessage('This stylist is not available for chat')
                      }
                      setLoading(false)
                    }
                  }}>Videochat
                </button>
              </>}

              {/*<FontAwesomeIcon disabled={loading} className='cursor-pointer mx-2' icon={faVideo} size="2x"*/}
              {/*                 onClick={async () => {*/}
              {/*                   if (!loading) {*/}
              {/*                     setLoading(true)*/}
              {/*                     setMessage(null)*/}
              {/*                     const r = await userContext.startVideoChat(s.originalId)*/}
              {/*                     if (!r) {*/}
              {/*                       setMessage("This stylist is not available for videochat")*/}
              {/*                     }*/}
              {/*                     setLoading(false)*/}
              {/*                   }*/}
              {/*                 }}/>*/}
            </div>
            {loading ?
              <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/> : null}
            {message ? <div className="font-sans font-light bg-red-300 p-1 my-2">{message}</div> :
              <div className="mt-2 p-1"/>}

            {s.city && <div>
              <FontAwesomeIcon className='text-green-700' icon={faMapMarkerAlt} size="1x"/>
              <span className='font-sans font-light mx-2'>{s.city.name}</span>
            </div>
            }
            <span className='font-sans font-light'>{s.experience}</span>
            <Like model={'stylist'} id={s.originalId}/>
            <Link to={'/booking/?stylistId=' + s.originalId}
                  className='mt-4 text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'>Book
              now</Link>
          </div>
        </div>
        <div
          className="text-purple-700 font-sans uppercase text-xl tracking-wide font-light mt-8 mb-2">Services
        </div>
        {s.category && Array.isArray(s.category) && s.category.map(c => <div key={c}
                                                className='font-sans font-light text-base my-1'>{categories[c]}</div>)}
        <div
          className="text-purple-700 font-sans uppercase text-xl tracking-wide font-light mt-8 mb-2">LookBook
        </div>
        <div className="flex flex-wrap justify-center w-full">
          {s.portfolio.map(i => <Img className='flex-1 mx-2 my-2' key={i.originalId} style={{minWidth: 400}}
                                     fluid={i.fluid}/>)}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
    query Stylist($originalId:String)  {
        datoCmsStylist(originalId:{eq:$originalId}) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            id
            originalId
            name
            experience
            avatar {
                fluid(maxWidth: 200, imgixParams: {fit: "crop", h: "200", w: "200"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
            portfolio {
                originalId
                fluid(maxWidth: 800, imgixParams: {fit: "crop", h: "800", w: "800"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
            category
            hourlyratedollar
            dailyratedollar
            city{
                name
            }
        }
    }
`
