import React, {useContext, useState} from "react";
import Layout from "../components/layout";
import Packages from "../components/Packages";
import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import {Field, Form, Formik} from "formik";
import {graphql} from 'gatsby'
import moment from "moment";
import AutoSuggestEnhanced from "../components/auto-suggest-enhanced";
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {UserContext} from "../context/UserContext";
import {Elements} from 'react-stripe-elements';
import InjectedPaymentForm from '../components/PaymentForm';
import ShoppingPackageDetail from "../components/ShoppingPackageDetail";
import StylistDetail from "../components/StylistDetail";
import SEO from "../components/seo";
import {client} from '../apollo/client';
import {ReferrerContext} from "../context/ReferrerContext";

const INSERT_BOOKING_REQUEST = gql`
    mutation InsertBookingRequest($input:InsertBookingRequestInput!) {
        insertBookingRequest(input: $input) {
            label
            reductionLabel
            reduction
            price
            total
            bookingRequestID
        }
    }
`

const CustomInputComponent = ({
                                field,
                                form: {touched, errors},
                                type = 'text',
                                ...props
                              }) => (
  <div>
    <input className={inputClass} type={type} {...field} {...props} />
    <div className={errorClass + (touched[field.name] &&
    errors[field.name] ? ' ' : ' invisible')}>{errors[field.name]}&nbsp;</div>
  </div>
);

const RadioButton = ({
                       name,
                       value,
                       label,
                       onChange = () => {
                       }
                     }) => <label htmlFor={value}
                                  className={'font-sans font-light text-gray-700 text-base mr-8'}>
  <Field name={name}
         render={({field, form}) => <input onClick={() => {
           onChange(field, form);
         }
         } id={value} className={'mr-2'} type="radio" {...field}
                                           checked={field.value === value} value={value}/>}/>{label}
</label>

const Checkbox = ({name, value, oneColumn}) => <Field name={name}>
  {({field, form}) => {
    return (
      <label className={'py-1 ' + (oneColumn ? 'w-full' : 'w-1/2')}>
        <div
          className={"inline-block mr-2 border border-black" + (field.value && field.value.includes(value) ? ' bg-yellow-700' : '')}>
          <input
            className='invisible'
            type="checkbox"
            name={name}
            value={value}
            checked={field.value && field.value.includes(value)}
            onChange={() => {
              if (field.value && field.value.includes(value)) {
                const nextValue = field.value.filter(
                  v => v !== value
                );
                form.setFieldValue(name, nextValue);
              } else {
                const nextValue = (field.value || []).concat(value);
                form.setFieldValue(name, nextValue);
              }
            }}
          />
        </div>
        <span className="font-sans font-light text-gray-800 text-base">
        {value}
        </span>
      </label>
    );
  }}
</Field>;

const CheckboxGroup = ({name, values, label, oneColumn = false}) => <div className="flex flex-col w-full">
  <div className="font-sans font-light text-black text-lg">{label}</div>
  <div className="flex flex-wrap">
    {values.map(v => <Checkbox key={v} oneColumn={oneColumn} name={name} value={v}/>)}
  </div>
</div>

const inputClass = 'font-sans font-light appearance-none border rounded w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline text-lg'
const errorClass = "text-red-700 text-xs italic mt-1";

const BookingPage = ({data: {allDatoCmsCity: cities}, location: {search}}) => {
  const userContext = useContext(UserContext);
  const {referrer} = useContext(ReferrerContext);
  const [payment, setPayment] = useState(null)


  let shoppingPackageId = null
  let stylistId = null
  if (search) {
    const parts = search.slice(1).split('=')
    if (parts[0] === 'shoppingPackageId') {
      shoppingPackageId = parts[1]
    }
    if (parts[0] === 'stylistId') {
      stylistId = parts[1]
    }
  }


  const BookingForm = () => (
    <Mutation
      client={client}
      mutation={INSERT_BOOKING_REQUEST}>{
      (insertBookingRequest) => {
        const user = userContext.user && userContext.user.idToken.payload
        return (
          <Formik
            initialValues={{
              email: user ? user.email : '',
              lastName: user ? user.family_name : '',
              firstName: user ? user.given_name : '',
              phone: '',
              city: '',
              // date: moment().add(1, 'days').hour(12).minute(0).second(0).millisecond(0),
              date: '',
              sex: null,
              cityResidence: '',
              duration: null,
              stylistCategory: null,
              stylistType: null,
              stylingType: null,
              description: '',
              styles: [],
              bundles: [],
              shoppingPackageId,
              stylistId,
              discountCode: ''
            }}
            validate={values => {
              let errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }}

            onSubmit={({recaptcha, ...input}, {setSubmitting, setStatus, setErrors}) => {
              input.date = input.date && input.date.format("DD/MM/YYYY HH:mm")
              input.referrer = referrer
              setStatus(null)
              insertBookingRequest({variables: {input}})
                .then(({data: {insertBookingRequest}}) => {
                  if (insertBookingRequest) {
                    setPayment(insertBookingRequest)
                  } else {
                    setStatus({error: false});
                  }
                  setSubmitting(false);
                })
                .catch(e => {
                  if (e.message === 'GraphQL error: invalid discountCode') {
                    setErrors({discountCode: 'Invalid discount code'})
                  } else {
                    setStatus({error: true});
                  }
                  setSubmitting(false);
                })
            }}
          >{({values, touched, errors, setTouched, setFieldValue, isSubmitting, status}) => <Form>
            <div className='flex flex-wrap w-full'>
              <div className="w-full md:w-1/2 md:pr-4">
                <Field component={CustomInputComponent} name='firstName' placeholder='First Name'/>
              </div>
              <div className="w-full md:w-1/2">
                <Field component={CustomInputComponent} name='lastName' placeholder='Last Name'/>
              </div>
            </div>
            <div className='flex flex-wrap w-full'>
              <div className="w-full md:w-1/2 md:pr-4">
                <Field component={CustomInputComponent} name='email' placeholder='Email'/>
              </div>
              <div className="w-1/2"/>
            </div>
            <div className='font-sans font-light text-gray-900 text-xl'>Gender</div>
            <div className='flex w-full mb-2'>
              <RadioButton name='sex' value='f' label='Female'/>
              <RadioButton name='sex' value='m' label='Male'/>
            </div>
            <div className='font-sans font-light text-gray-900 text-xl'>What do you want?</div>
            <div className='flex w-full mb-2'>
              <RadioButton name='stylingType' value='virtual' label='Virtual Styling'/>
              <RadioButton name='stylingType' value='personal' label='Personal Styling'/>
            </div>
            <div className='flex flex-wrap w-full'>
              <div className="w-full md:w-1/2 md:pr-4">
                <Field component={CustomInputComponent} name='cityResidence' placeholder='City of residence'/>
              </div>
              <div className="w-full md:w-1/2">
                <AutoSuggestEnhanced
                  id="city"
                  placeholder='Shopping Destination'
                  initialValue={values.city}
                  items={cities.edges.map(v => v.node)}
                  onBlur={() => setTouched({...touched, 'city': true})}
                  onSelected={e => setFieldValue('city', e && e.name)}/>
                <div className={errorClass + (touched.city &&
                errors.city ? ' ' : ' invisible')}>{errors.city}&nbsp;</div>
              </div>
            </div>
            <div className='flex flex-wrap w-full'>
              <div className="w-full md:w-1/2 md:pr-4">
                <Field component={CustomInputComponent} name='phone' placeholder='Phone'/>
              </div>
              <div className="w-full md:w-1/2"/>
            </div>
            <div className='flex flex-wrap'>
              <div className="w-full md:w-1/2 flex flex-col md:pr-4">
                <div className='font-sans font-light text-gray-900 text-xl'>Date & Time</div>
                <Datetime
                  dateFormat='DD/MM/YYYY'
                  timeFormat={'HH:mm'}
                  value={values.date}
                  inputProps={{placeholder: 'Date'}}
                  isValidDate={c => c.isAfter(moment())}
                  onBlur={() => setTouched({...touched, 'date': true})}
                  onChange={e => {
                    return setFieldValue('date', e);
                  }}
                  timeConstraints={{minutes: {step: 15}}}
                  className={'booking_date'}/>
                <div
                  className={errorClass + (touched.date && errors.date ? ' ' : ' invisible')}>{errors.date}&nbsp;</div>
                <div className='font-sans font-light text-gray-900 text-xl'>Duration</div>
                <div className='flex flex-wrap w-full mb-2'>
                  <RadioButton name='duration' value='fullday' label='Full Day'/>
                  <RadioButton name='duration' value='halfday' label='Half Day'/>
                  <RadioButton name='duration' value='weekend' label='Weekend'/>
                  <RadioButton name='duration' value='halfhour' label='30 Minutes'/>
                  <RadioButton name='duration' value='fullhour' label='1 Hour'/>
                  <RadioButton name='duration' value='twohour' label='2 Hours'/>
                  <RadioButton name='duration' value='threehour' label='3 Hours'/>
                </div>
                <div className='font-sans font-light text-gray-900 text-xl'>Service</div>
                <div className='flex w-full mb-2'>
                  <RadioButton name='stylistCategory' value='fashion' label='Fashion'/>
                  <RadioButton name='stylistCategory' value='hair' label='Hair'/>
                  <RadioButton name='stylistCategory' value='makeup' label='Makeup'/>
                </div>
                <div className='font-sans font-light text-gray-900 text-xl'>Stylist</div>
                <div className='flex w-full mb-2'>
                  <RadioButton name='stylistType' value='experienced' label='Experienced Stylist'/>
                  <RadioButton name='stylistType' value='celebrity' label='Celebrity Stylist'/>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <CheckboxGroup name='styles' label='Style' values={[
                  'Yet to discover',
                  'Elegant',
                  'Stylish',
                  'Sporty',
                  'Formal',
                  'Chich',
                  'Urban',
                  'Street',
                  'Casual',
                  'Trendy',
                  'Business',
                  'Other',
                ]}/>
              </div>
            </div>
            <div className='font-sans font-light text-gray-900 text-xl mt-2'>Please Briefly Describe the purpose of
              your
              booking
            </div>
            <div className="w-full mb-2">
              <Field component='textarea' name='description' className={inputClass} rows={5}/>
            </div>
            <CheckboxGroup name='bundles' label='Bundle up!' oneColumn={true} values={[
              'Flight',
              'Private Accomodation',
              'Public Accomodation',
              'Dining - Breakfast, Lunch, Dinner',
              'Spa',
              'Other Beauty Treatments',
              'City Tour',
              'Party',
              'Cruise',
            ]}/>
            <div className='flex flex-wrap w-full mt-8'>
              <div className="w-full md:w-1/2 md:pr-4">
                <Field component={CustomInputComponent} name='discountCode' placeholder='Discount Code'/>
              </div>
              <div className="w-1/2"/>
            </div>
            <div className="flex flex-col mb-4 items-start">
              <button
                className='send my-2 text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'
                type="submit" disabled={isSubmitting}>
                Submit
              </button>
              {isSubmitting ?
                <FontAwesomeIcon className='text-gray-800' icon={faSpinner} spin={true} size="2x"/> : null}
              <div
                className={'my-2 w-full font-sans font-light py-2 px-8 ' + (status ? (status.error ? 'bg-red-300' : 'bg-green-300') : 'invisible')}>
                {status && status.error ? 'Something went wrong, please try again later' : 'Your booking request was sent successfully.'}
              </div>

            </div>
          </Form>}
          </Formik>
        );
      }
    }</Mutation>
  )

  return (<Layout>
    <div className="mx-2 lg:mx-64">
      <SEO title="Booking" keywords={[`about us`, `fashion`]}/>
      <div className="font-title font-bold text-4xl uppercase mt-8 mb-2 text-left tracking-wider">Book your Look
        Styler!
      </div>
      {shoppingPackageId && <ShoppingPackageDetail shoppingPackageId={shoppingPackageId}/>}
      {stylistId && <StylistDetail stylistId={stylistId}/>}
      {!userContext.loading && !payment && <BookingForm/>}
      {payment && <div className={'mt-16 mb-64'}>
        <div className='flex'>
          <div className="font-sans font-light text-xl w-1/2">{payment.label}</div>
          <div className="font-sans font-light text-xl w-1/2 text-right">{payment.price}</div>
        </div>
        <div className='flex'>
          <div className="font-sans font-light text-xl w-1/2">{payment.reductionLabel}</div>
          <div className="font-sans font-light text-xl w-1/2 text-right">{payment.reduction}</div>
        </div>
        <div className='flex mt-4 mb-8'>
          <div className="font-sans text-xl w-1/2">Total</div>
          <div className="font-sans text-xl w-1/2 text-right">{payment.total}</div>
        </div>

        <Elements>
          <InjectedPaymentForm bookingRequestID={payment.bookingRequestID}/>
        </Elements>
      </div>}
    </div>
    <Packages/>
  </Layout>);
}

export default BookingPage

export const query = graphql`
    query BookingPage {
        allDatoCmsCity(sort: {fields: [name]}) {
            edges {
                node {
                    name
                    slug
                    country
                    imagelist {
                        fluid(maxWidth: 400, maxHeight:400, imgixParams: { auto: "compress,format" }) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
            }
        }
    }`

