import React from "react";

import {datoClient} from '../apollo/client';
import gql from "graphql-tag";
import {Query} from "react-apollo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faCreditCard, faMapMarkerAlt, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";

export const SHOPPING_PACKAGE = gql`query ShoppingPackage($id: ItemId!) {
    shoppingpackage(filter: {id: {eq: $id}}) {
        name
        durationhours
        slug
        pricedollar
        city {
            id
            name
        }
        image {
            url
        }
        images {
            id
            url
        }
    }
}
`

const ShoppingPackageDetail = ({shoppingPackageId}) => <Query
  query={SHOPPING_PACKAGE}
  variables={{id: shoppingPackageId}}
  client={datoClient}
>{({loading, error, data, ...v}) => {
  if (loading) {
    return <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/>
  }
  if (error) {
    return <div className="font-sans text-red-700">Something went wrong, please try again later</div>
  }
  const p = data.shoppingpackage
  return <div className='w-full border mb-8'>
    <div className="flex justify-center">
      {p.images.map(i => {
        return <div key={i.id} className="object-contain">
          <img src={i.url} alt=""/>
        </div>;
      })}
    </div>
    <div className="p-2">
      <div className="flex flex-wrap justify-between items-end">
        <div className="flex flex-col">
          <h1 className="font-title font-bold text-2xl uppercase tracking-wider">{p.name}</h1>
          <div className="flex">
            <FontAwesomeIcon className='text-green-700' icon={faClock} size="1x"/>
            <span className='ml-2 mr-8 font-sans text-gray-800'>{p.durationhours} hours</span>
            <FontAwesomeIcon className='text-green-700' icon={faCreditCard} size="1x"/>
            <span className='ml-2 mr-8 font-sans text-gray-800'>{p.pricedollar}$</span>
            <FontAwesomeIcon className='text-green-700' icon={faMapMarkerAlt} size="1x"/>
            <span className='ml-2 mr-8 font-sans text-gray-800'>{p.city && p.city.name}</span>
          </div>
        </div>
        <Link to={'/shopping-packages/' + p.slug + '/'}
              className='text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'>View
          details</Link>
      </div>
    </div>
  </div>
}}</Query>

export default ShoppingPackageDetail