import {graphql, Link, useStaticQuery} from "gatsby";
import React from "react";
import Img from "gatsby-image";

const Packages = () => {
  const data = useStaticQuery(graphql`
    query Packages {
      datoCmsServicespage {
        descriptionpackages
        imagepackages {
          originalId
          fluid(maxWidth: 300, imgixParams: {fit: "crop", w:"600", h:"800"}) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }`)
  const p = data.datoCmsServicespage

  return <section className="flex flex-wrap mt-2 mb-8">
    {
      p.imagepackages.map(i => <Img key={i.originalId} className='m-2 md:m-4 rounded-lg w-full md:flex-1'
                                    style={{height: '400px'}}
                                    fluid={i.fluid}/>)
    }
    < div className="flex flex-col md:w-1/3 justify-center items-center">
      <h1
        className='font-sans text-3xl uppercase mt-8 mb-2 tracking-wide text-center'>Travel & shopping
        packages</h1>
      <div className='font-sans font-light mt-4 text-center'
           dangerouslySetInnerHTML={{__html: p.descriptionpackages}}/>
      <Link to='/shopping-packages/'
            className='mt-4 bg-yellow-700 no-underline font-title tracking-wide text-white px-4 py-2'>Explore
        our travel partners</Link>
    </div>
  </section>

}

export default Packages